import { graphql, PageProps } from 'gatsby';
import * as React from 'react';

type Props = PageProps<{ sanityPost: { _id: string; title: string } }, { id: string }>

const BlogPage: React.FC<Props> =({ data }) => {
    console.log(data)
    return (
    <div>
        {data.sanityPost.title}
    </div>
    )
}

export default BlogPage;

export const query = graphql`
  query BlogPost($id: String!) {
    sanityPost(id: { eq: $id }) {
        _id
        title
        _rawBody
    }
  }    
`;
